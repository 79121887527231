import React from 'react'
import { MwButton, MwSpinnerButton, NoDataFound } from '../ui'
import LineChart from '../charts/LineChart'
import { Link } from 'react-router-dom'

const MonthlyLineChart = ({
    tenantUsername,
    loading,
    monthlyList,
    label

}) => {
  return (
    <>
            {
                loading ? 
                    <MwSpinnerButton isFullCenter={true} withLabel={false}/>
                    : monthlyList.length > 0 ?
                        <div className={`flex-1 ${monthlyList.length === 1 ? 'flex-col' : 'flex-row gap-3' }`}>
                            { 
                                
                                    <LineChart 
                                        label={label} 
                                        datasetData = {monthlyList} 
                                        loading={loading} 
                                        /> 
                            }
                        </div> :
                        <NoDataFound msg={`لا يوجد تذاكر دعم`}>
                            <div className='flex gap-3  justify-center items-center'>
                                <Link to={`/${tenantUsername}/tickets/add`}>
                                    <MwButton type='saveBtn'>أنشاء تذكرة دعم فني جديدة</MwButton> 
                                </Link>
                            </div>
                        </NoDataFound>
            } 
    </>
  )
}

export default MonthlyLineChart